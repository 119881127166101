
import { defineComponent, ref } from "vue";
import SbomDataService from "@/services/SbomDataService";
import ResponseData from "@/types/ResponseData";
import { vulSeverityList } from "@/utils"
import { Search } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus';
import { mapGetters} from 'vuex';
function defaultPagination() {
  return {
    pageNum: 1,
    pageSize: 10,
    total: 0
  }
}
const columns1 = [
  { label: '漏洞编号', prop: 'vulId', type: 'link' },
  { label: 'Issue编号', prop: 'issueId' },
  { label: '漏洞状态', prop: 'state' },
  { label: '修复原因', prop: 'reason' },
  { label: '操作', prop: 'operate', type: 'button', width: 150 },
]
const columns2 = [
  { label: '漏洞编号', prop: 'vulId', type: 'link', minWidth: 250 },
  { label: '漏洞评分系统', prop: 'scoringSystem', width: 250 },
  { label: '漏洞评分', prop: 'score', width: 250, hasBgcolor: true },
  { label: '漏洞评分向量', prop: 'vector', minWidth: 400, showTooltip: true },
  { label: '漏洞关联组件purl', prop: 'purl', width: 400, showTooltip: true },
  { label: '操作', prop: 'operate', type: 'button', width: 150 },
]
export default defineComponent({
  name: "SbomVulnerabilityTable",
  components: {},
  props: {
    packageId: {
      type: String,
      default: ''
    },
    severity: {
      type: String,
      default: ''
    },
    productName: {
      type: String,
      default: ''
    },
    isByProductName: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      Search: Search,
      pagination: defaultPagination(),
      tableData: [] as Map<string, any>[],
      graphColors: [
        { label: '漏洞', prop: 'vulnerability', color: '#FF0000' },
        { label: '漏洞影响的组件', prop: 'dependency', color: '#0dcfcf' },
        { label: '漏洞影响的软件', prop: 'package', color: '#FF9126' },
        { label: '漏洞影响的传递性依赖', prop: 'transitiveDep', color: '#5A94F8' },
      ],
      colors: {
        vulnerability: '#FF0000',
        dependency: '#0dcfcf',
        package: '#FF9126',
        transitiveDep: '#5A94F8',
      },
      vulSeverityList,
      vulId: '',
      vulSeverity: '',
    };
  },
  computed:{
    ...mapGetters([
      'getProductName'
    ]),
    columns() {
      if(this.isByProductName) {
        return columns1
      } else {
        return columns2
      }
    }
  },
  watch: {
    severity(newVal) {
      this.vulSeverity = newVal
      this.search()
    },
    packageId() {
      this.search()
    },
    productName() {
      if(this.isByProductName) {
        this.queryPackageVulnerability()
      }
    }
  },
  methods: {
    search() {
      this.pagination.pageNum = 1
      this.queryPackageVulnerability()
    },
    handlePageChange(val: number) {
      this.pagination.pageNum = val
      this.queryPackageVulnerability()
    },
    handleSizeChange( val: number) {
      this.pagination.pageNum = 1
      this.pagination.pageSize = val
      this.queryPackageVulnerability()
    },
    queryPackageVulnerability() {
      this.tableData = []
      if(this.isByProductName) {
        let str = `page=${this.pagination.pageNum - 1}&size=${this.pagination.pageSize}`
        if(this.packageId) {
          str = `${str}&packageId=${this.packageId}`
        }
        if(this.vulSeverity) {
          str = `${str}&severity=${this.vulSeverity}`
        }
        if(this.vulId) {
          str = `${str}&vulId=${this.vulId}`
        }
        SbomDataService.queryVulnerability(this.productName, str)
        .then((response: ResponseData) => {
          if(this.isByProductName) {
            response.data.content.forEach(item => {
              item.state = item.state || item.issueCustomizeState || '--';
              item.reason = item.reason || 'N/A';
            });
          }
          this.tableData = response.data.content;
          this.pagination.total = response.data.totalElements;
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
            message: e.response.data,
            type: 'error'
            })
          }
          this.tableData = []
          this.pagination.total = 0
        });
      } else {
        let str = `page=${this.pagination.pageNum - 1}&size=${this.pagination.pageSize}`
        if(this.vulSeverity) {
          str = `${str}&severity=${this.vulSeverity}`
        }
        if(this.vulId) {
          str = `${str}&vulId=${this.vulId}`
        }
        SbomDataService.queryPackageVulnerability(
          this.packageId, 
          str
        )
          .then((response: ResponseData) => {
            this.tableData = response.data.content;
            this.pagination.total = response.data.totalElements;
          })
          .catch((e: any) => {
            if (e.response && e.response.status == 500) {
              ElMessage({
              message: e.response.data,
              type: 'error'
              })
            }
            this.tableData = []
            this.pagination.total = 0
          });
      }
    },
    goPath(item: any) {
      if(item.references && item.references.length) {
        const url = item.references[0].second
        window.open(url, '_blank')
      }
    },
    formatCellColor(score: number) {
      if(score <= 0) {
        return 'none'
      } else if (score > 0 && score < 4) {
        return 'low'
      } else if (score >= 4 && score < 7) {
        return 'medium'
      } else if (score >= 7 && score < 9) {
        return 'high'
      } else if (score >= 9) {
        return 'critical'
      } else {
        return ''
      }
    },
    openEchart(rowInfo) {
      const { href } = this.$router.resolve({
        path: "/sbomVulImpactChart",
        query: {
          productName: this.productName,
          vulId: rowInfo.vulId,
        },
      });
      window.open(href, '_blank');
    },
  },
  mounted() {
    this.vulSeverity = this.severity
    this.queryPackageVulnerability();
  },
});
